var utils = require('../utils');
var currentRefinementContainerScrollPos = 0;
var $loaderContainer = $('body');
var scrollHeightOffset = $('#headerComponent').outerHeight();
var selectableFilters = ['neck', 'size', 'waist', 'length'];
let fetchNextInProgress = false;

var pageSize = $('input[name="pageSize"]').val();
var loadMoreCount = $('input[name="loadMoreCount"]').val();
var isMobile = utils.getCurrentBreakpoint() === 'mobile';
var deviceType = utils.getCurrentBreakpoint() === 'mobile' ? 'mobile' : 'desktop';

var isFilterUpdate = false;

/**
 *  Keep accordians open
 * @param {array} openAccordianValues - Values for currently open accordians
 */
function updateAccordian(openAccordianValues) {
    openAccordianValues.forEach(element => {
        let $filter = $('.enable-drawer[data-refinementname="' + element + '"]');
        if ($filter.data('sort-default') !== 'on') {
            $filter.find('.accordionHeader button.btn')
            .removeClass('collapsed')
            .attr('aria-expanded', 'true');
            $filter.find('.content').addClass('show');
        } else {
            $filter.data('sort-default', 'off');
        }
    });
}

/**
 * filter close for mobile
 * @param {string} element -
 * @param {boolean} scrollToTop - when filter close at that time user goes to top on the page
 */
function filterClose(element, scrollToTop) {
    var productGrid = scrollToTop ? $(element).offset() : $(element);
    let searchCloseFilterModal = $(document).find('.search-page-only');
    let searchFilterFlag = $(document).find('.searchFilterModalClose');
    setFilterAccordionState();
    $('.overlay-filter').fadeOut();
    setTimeout(function () {
        // FIXME this if condition is for SRP page only clean up for PLP page
        if (searchCloseFilterModal.length) {
            if (isMobile) {
                utils.unlockPage();
                utils.scrollWithoutAnimation(0, scrollToTop ? -productGrid.top : productGrid.offset().top);
            } else {
                utils.searchPlpUnlock();
            }
        } else {
            utils.unlockPage();
            utils.scrollWithoutAnimation(0, scrollToTop ? -productGrid.top : productGrid.offset().top);
        }

        if (searchCloseFilterModal.length && searchFilterFlag.attr('data-searchmodalclose') === 'true') {
            searchFilterFlag.attr('data-searchmodalclose', 'false');
        }
    }, 200);
    if ($('#refinebarModal').hasClass('show')) {
        utils.closeModal($('#refinebarModal'));
        return;
    }
    setTimeout(() => {
        $('.left-filter').removeClass('filter-open');
        $('body').removeClass('filter-open');
    }, 300);
    setTimeout(() => {
        $('.fltroverlays').remove();
    }, 800);
    $('.overlay-filters').hide();
    if ($('.card .card-body').hasClass('show') && $('#clearSelectedFilter').length === 0) {
        $('.btn-collapse-hedding').addClass('collapsed');
        $('.card-body').removeClass('show');
    }
}

/**
 * Set current state of filter accordions to session
 * @returns {array} openAccordianValues list of accordions
 */
function setFilterAccordionState() {
    // eslint-disable-next-line
    let openAccordianValues = $('.enable-drawer[data-refinementname]:visible').filter(function () {
        const $elem = $(this);
        const isButtonOpen = $elem.find('.accordionHeader button.btn:not(.collapsed)').length;
        const hasSelection = $elem.hasClass('has-selection');
        const isSortDrawer = $elem.attr('id') === 'sort-drawer' && $elem.find('.accordionHeader button.btn').hasClass('collapsed');

        // Return true only for valid elements, and retrieve 'data-refinementname'
        return (isButtonOpen || hasSelection) && !isSortDrawer;
    }).map(function () {
        return $(this).attr('data-refinementname');
    }).get();

    if (window.sessionStorage) {
        window.sessionStorage.setItem('filterState', JSON.stringify(openAccordianValues));
    }

    return openAccordianValues;
}


/**
 * update filter accordion state
 */
 function updateFilterAccordionState() {
    let openAccordianValues = [];
    // update accordion status based on previously stored state
    try {
        if (!isMobile || $('.ds-left-filter').hasClass('filter-open') || $('#accordion .enable-drawer').hasClass('has-selection')) {
            let storedFilterState = JSON.parse(window.sessionStorage.getItem('filterState')) || [];
            openAccordianValues = storedFilterState;

            // Ensure openAccordianValues is set to a valid value
            if (!openAccordianValues || openAccordianValues.length === 0) {
                openAccordianValues = setFilterAccordionState();
            }
            updateAccordian(openAccordianValues);
        }
    } catch (error) {
        // no logging required
    }
}

/**
 *  Replace Existing Sorting Rule
 * @param {string} previousUrl - Previous Url
 * @param {string} pmin - min price to be added to url
 * @param {string} pmax - max price to be added to url
 * @returns {string} New url containing new sorting rule
 */
function updatePriceRange(previousUrl, pmin, pmax) {
    var params = utils.getQueryStringParams(previousUrl.split('?')[1]);
    params.pmin = pmin.toFixed(2);
    params.pmax = pmax.toFixed(2);
    var orginialUrl = previousUrl ? previousUrl.split('?')[0] : window.location.href;
    var newUrl = utils.appendToUrl(orginialUrl, params);
    return newUrl;
}

// eslint-disable-next-line require-jsdoc
function updatePriceFilters(selectedPriceFilters, filterButtonUrl, index) {
    selectedPriceFilters.splice(index, 1);
    var priceFilterApplied = false;
    var pmin = Number.POSITIVE_INFINITY;
    var pmax = Number.NEGATIVE_INFINITY;
    $('.refinementValue_price').each(function () {
        if ($(this).children().is('input:checked')) {
            var actualpricerange = $(this).data('actualpricerange');
            pmin = pmin < actualpricerange[0] ? pmin : actualpricerange[0];
            pmax = pmax > actualpricerange[1] ? pmax : actualpricerange[1];
            priceFilterApplied = true;
        }
    });
    if (priceFilterApplied) {
        // eslint-disable-next-line no-param-reassign
        filterButtonUrl = updatePriceRange(filterButtonUrl, pmin, pmax);
    }
    window.sessionStorage.setItem('selectedPriceFilters', JSON.stringify(selectedPriceFilters));
    return filterButtonUrl;
}

/**
 * Define loader container based on device type
 * @param {boolean} overlay should open
 */
function setLoaderContainer() {
    // set to true if the click happen inside filter overlay
    const isOverlay = $('.refinements, .clearall-view').length && isMobile;

    if (isOverlay) {
        $loaderContainer = $('.filter-open.left-filter');
    } else {
        $loaderContainer = $('.search-page:first');
    }
}

/**
 * update category carousel state when refinements are engaged
 * @param {string} filterButtonUrl should open
 */

function updateCarouselState(filterButtonUrl) {
    if ($('.filterTile').length) {
        var merge = require('lodash/merge');
        var urlParamsObj = utils.getUrlParamAsObject(`${location.origin}${filterButtonUrl}`);
        var mergedObj = merge(urlParamsObj.urlParmas, urlParamsObj.nonPref);

        $('.filterTile').each(function () {
            let tileRefinenments = utils.updateURLValues($(this).data('refinementquery'));
            let isExists = false;
            Object.entries(tileRefinenments).forEach(
                ([key, value]) => {
                    if (mergedObj[key]) {
                        isExists = value.every(val => mergedObj[key].includes(val.trim()));
                    } else {
                        isExists = false;
                    }
                }
            );

            if (isExists) {
                $(this).data('selected', true);
                $(this).addClass('selectedCategory');
            } else {
                $(this).data('selected', false);
                $(this).removeClass('selectedCategory');
            }
        });
    }
}
/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @param {function} cb callback function
 * @return {undefined}
 */
function handleRefinements($results, cb) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });


    updateDom($results, '.refinements-container');
    $('#refinebarModal .refinements-container').css('opacity', '0');

    if (typeof cb === 'function') {
        cb();
    }
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    updateRefinementContainerPos();

    var $results = $(response);
    var specialHandlers = {
        '.refinements-container': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.search-result-count',
        '.search-count',
        '.view-filter',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar.selected-refinements',
        '.sort-by-main',
        '.ds-show-filters-btn-mobile',
        '.no-results-container',
        '.no-search-results',
        '.ds-main-heading',
        '.seo-content-container--title',
        '.ds-l1-categories',
        '.refinebar'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    let selectedFilters = $results.find('.selectedFiltersAnalytics').val();
    $('.selectedFiltersAnalytics').val(selectedFilters);

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results, () => {
            setTimeout(() => {
                updateRefinementContainerPos(currentRefinementContainerScrollPos);
                $(selector).css('opacity', '1');
            }, 10);
        });
    });
}

/**
 * Scroll PLP page when refinments/sort is clicked
 */
function handleRefinementChange() {
    setTimeout(() => {
        if ($(document).find('.search-page-only').length) {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        } else {
            window.scroll({ top: $('.js-search-results').offset().top - scrollHeightOffset, left: 0, behavior: 'smooth' });
        }

        if ($(document).find('.search-page-only').length && $(document).find('.searchFilterModalClose').attr('data-searchmodalclose') === 'false') {
            $(document).find('.searchFilterModalClose').attr('data-searchmodalclose', 'true');
        }
    }, 300);
}


/**
 * scroll to correct gender pill position mobile
 */
function genderPillPosition(currentURL) {
    var $genderPillScroll = $('.ds-l1-categories ul');
    let urlObj = new URL(currentURL, window.location.origin);
    let params = new URLSearchParams(urlObj.search);

    // Loop through all the 'prefn' parameters to check for gender
    let genderValue;
    for (let [key, value] of params.entries()) {
        if (key.startsWith('prefn') && value.toLowerCase() === 'gender') {
            // If 'gender' is found in a prefn*, get the corresponding prefv*
            let prefvKey = key.replace('prefn', 'prefv');
            let genderValues = params.get(prefvKey).split('|');
            genderValue = genderValues[genderValues.length - 1];
            break;
        }
    }

    if (genderValue) {
        let pillClass = `.pill-${genderValue.replace('|', '\\|')}`;
        let $pillScrollTo = $(pillClass); // Find the pill element with that class

        // Scroll to the gender pill if it exists
        if ($pillScrollTo.length > 0) {
            $genderPillScroll.animate({
                scrollLeft: $genderPillScroll.scrollLeft() + $pillScrollTo.position().left + $pillScrollTo.outerWidth() + 2 - $genderPillScroll.width()
            }, 0);
        }
    }
}

/**
 * Initialize PD script
 * @param {Object} response - jQuery DOM element
 */
function initializePageDesignerScript(response) {
    let $productListPD = $(response).find('.experience-dynamic-productList');
    if ($productListPD.length) {
        $('body').trigger('pagedesigner:initComponent');
    }
}

/**
 * update refinements container scroll pos after reload.
 * @param {number} currentPos current scroll position
 */
function updateRefinementContainerPos(currentPos) {
    var $refinementContainer = $('.ds-sort-filter.ds-drawers')[0];

    if (utils.getCurrentBreakpoint().indexOf('desktop') >= 0) {
        $refinementContainer = $('.ds-left-filter')[0];
    }

    if (currentPos && $refinementContainer) {
        $refinementContainer.scrollTop = currentPos;
    } else if ($refinementContainer) {
        currentRefinementContainerScrollPos = $refinementContainer.scrollTop;
    }
}

function onLoad() {
    const $refinementDrawer = $('.enable-drawer');

    // update filterstate when close
    $refinementDrawer.on('hidden.bs.collapse', ($elem) => {
        $($elem.currentTarget).removeClass('open');
        setFilterAccordionState();
    });

    // update filter state when open
    $refinementDrawer.on('shown.bs.collapse', ($elem) => {
        $($elem.currentTarget).addClass('open');
        setFilterAccordionState();
    });
    // reopen filters based on previously saved state or the one with filters
    updateFilterAccordionState();
}

/**
 * Mobile Refinebar open
 */
function filterOpen() {
    var overlayHtml = '<div class="fltroverlays"></div>';
    $(overlayHtml).prependTo('.left-filter ');
    let $overlayFilter = $('.overlay-filters');
    let $refinementBar = $('.refinement-bar');

    setTimeout(() => {
        utils.lockPage();
        utils.setWindowHeight();
        $('body').addClass('filter-open');
        $('.search-results .ds-left-filter.left-filter').addClass('filter-open');

        if ($refinementBar.length) {
            $overlayFilter.show();
            $refinementBar.show();
        }
    }, 10);
    isFilterUpdate = false;

    setTimeout(() => {
        updateFilterAccordionState();
    }, 300);
}

/**
 * Show selected price filter pills and update the selected filters count
 */
function handlePriceFilter() {
    var selectedPriceFilters = window.sessionStorage.getItem('selectedPriceFilters') ? JSON.parse(window.sessionStorage.getItem('selectedPriceFilters')) : [];
    var priceFilterCount = 0;
    if (selectedPriceFilters && selectedPriceFilters.length) {
        for (var i = 0; i < selectedPriceFilters.length; i++) {
            var id = selectedPriceFilters[i];
            $('#' + id).children('input').attr('checked', 'checked');
            var $selectedFilterPill = $('.filter-bar.selected-refinements li.' + id);
            if ($selectedFilterPill.length) {
                priceFilterCount += 1;
                $selectedFilterPill.removeClass('d-none');
            }
        }
        $('.filter-value[data-refinement="price"] .refinement-count').html(`(${priceFilterCount})`);
    }
    if ($('.price_input:checkbox:checked').length > 0) {
        $('#clearSelectedPriceFilter').removeClass('d-none');
    }
}

/**
 * set the text for collapsed sort label
 */
function setDefaultSort() {
    var sortSelected = $('.sort-menu-item.selected .sort-menu-label').text();
    if (sortSelected) {
        $('.sort-current').text(sortSelected);
        $('#sort-drawer').data('sort-default', 'on');
    }
}

module.exports = {
    onLoad,
    setFilterAccordionState,
    setLoaderContainer,
    updatePriceRange,
    updatePriceFilters,
    updateCarouselState,
    parseResults,
    updateFilterAccordionState,
    handleRefinementChange,
    initializePageDesignerScript,
    updateRefinementContainerPos,
    filterClose,
    genderPillPosition,
    updateAccordian,
    filterOpen,
    handleRefinements,
    handlePriceFilter,
    setDefaultSort
};
